<script setup lang="ts">
// @ts-expect-error Could not find a declaration file for module 'vue-tel-input'
import { VueTelInput } from 'vue-tel-input'

import { ErrorMessage, useField } from 'vee-validate'
import type { Rules } from '../types'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    hasBorder?: boolean
    infoText?: string
    inputHtml?: string
    label?: string
    labelHtml?: string
    modelValue?: string
    name: string
    rules?: Rules
    wrapperClass?: string
  }>(),
  {
    disabled: false,
    hasBorder: true,
    infoText: '',
    inputHtml: '',
    label: '',
    labelHtml: '',
    modelValue: '',
    rules: '',
    wrapperClass: 'w-full mb-4',
  },
)

const emits = defineEmits<{
  'update:model-value': [string]
  'is-phone-valid': [boolean]
}>()

const inputOptions = {
  id: props.name,
  name: props.name,
  placeholder: '',
  autocomplete: 'on',
  autofocus: false,
  type: 'tel',
}

const rulesInput = reactive({
  required:
    typeof props.rules === 'string'
      ? props.rules.includes('required')
      : 'required' in props.rules,
  phone_invalid: false,
})

const {
  value: inputValue,
  handleChange,
  errors,
} = useField<string>(props.name, rulesInput, {
  initialValue: props.modelValue,
})

const emptyValue = computed(() => Boolean(!inputValue?.value?.length))
const isError = computed(() => Boolean(errors.value.length))
const oldValue = ref('')

watch(
  () => props.modelValue,
  (_, old) => {
    oldValue.value = old
  },
)

const onInput = (
  number: string,
  phoneObject: { formatted: string; valid: boolean },
) => {
  rulesInput.phone_invalid = emptyValue.value ? false : !phoneObject.valid
  emits('update:model-value', phoneObject.formatted)
  emits('is-phone-valid', phoneObject.valid)

  if (number) {
    handleChange(phoneObject.formatted)
  } else if (!number && oldValue.value.length) {
    handleChange('')
  }
}

const computedClass = computed((): string[] => {
  const computeClass = ['base-input']

  if (!props.hasBorder) {
    computeClass.push('base-tel-input--noBorder')
  }
  if (props.disabled) {
    computeClass.push('base-tel-input--disabled')
  }
  if (isError.value && props.hasBorder) {
    computeClass.push('base-tel-input--hasError-hasBorder')
  }
  if (!isError.value && props.hasBorder) {
    computeClass.push('base-tel-input--hasntError-hasBorder')
  }

  return computeClass
})

watch(
  () => props.rules,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      rulesInput.required =
        typeof newValue === 'string'
          ? newValue.includes('required')
          : 'required' in newValue
    }
  },
)
</script>

<template>
  <div :class="wrapperClass">
    <slot name="label">
      <label v-if="label" :for="name" class="flex">
        <BaseFormComponentsBaseFormLabel
          :label="label"
          :label-html="labelHtml"
        />
        <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
      </label>
    </slot>

    <vue-tel-input
      :auto-default-country="false"
      :class="computedClass"
      :disabled="disabled"
      :input-options="inputOptions"
      :model-value="inputValue"
      :preferred-countries="['fr', 'gb', 'us']"
      data-testid="base-tel-input"
      mode="international"
      v-bind="$attrs"
      valid-characters-only
      @on-input="onInput"
    />

    <BaseFormComponentsBaseStringToHtml
      v-if="inputHtml"
      :str-html="inputHtml"
    />

    <error-message :name="name" as="span" class="base-form--error" />
    <BaseFormComponentsBaseFieldInfo v-if="infoText" :text="infoText" />
  </div>
</template>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>

<style>
.base-tel-input {
  height: 51px;
  @apply border rounded-sm shadow-none;
}
.base-tel-input--noBorder {
  @apply border-none;
}
.base-tel-input--disabled {
  @apply bg-gray-200 text-gray-500 cursor-not-allowed;
}
.base-tel-input--hasError-hasBorder {
  @apply border-error;
}
.base-tel-input--hasntError-hasBorder {
  @apply border-gray-200;
}

.base-tel-input:focus-within,
.vue-tel-input:focus-within {
  @apply text-gray-500 bg-white border-primary-100 shadow-focus outline-none;
}
</style>
